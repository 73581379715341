import "@webhare-system/wh/integration"; //we're a WebHare site
import "@webhare-publisher/richcontent/all";
import * as dompack from "dompack";                                                                   
import * as dialog from 'dompack/components/dialog';
import * as dialogapi from 'dompack/extra/dialogapi';
import WHBase from "@webhare-system/compat/base";
import JSONRPC from "@webhare-system/net/jsonrpc";
import OverlayManager from "dompack-overlays";
import "../css/overlays.scss";                                                                             
import "../css/dialogs.scss";                                                                             

require('../css/site.css');

let options = { allowcancel:  false };
dialogapi.setupDialogs(options => dialog.createDialog('mydialog', options));

let jsonrpc = new JSONRPC({ url: "/wh_services/reunistenvandebsv/rpc/" });

function getHashVar(_varname, _querystring)
{
  let querystring = _querystring || location.hash;
  let queryvars = querystring.split("/");
  for(let queryvar of queryvars) {
    let qvars = queryvar.split("=");
    if (qvars[0] == _varname) {
      return qvars[1];
    }
  }
  return "";
}

document.addEventListener("DOMContentLoaded",function()
{
  let mystate = history.state;
}); //DOMContentLoaded

window.addEventListener("popstate",function(e)
{
  let mystate = history.state;
  if (mystate) {
    let params = {
      fullname: mystate.fullname
    , endyear: mystate.endyear
    , type: mystate.type
    , m: mystate.m
    }
    setSearchResults(params);
  }
});

window.addEventListener("load",function()
{
  let mystate = history.state;
  if (mystate) {
    let params = {
      fullname: mystate.fullname
    , endyear: mystate.endyear
    , type: mystate.type
    , m: mystate.m
    }
    setSearchResults(params);
  }
  else {
    let params = {
      fullname: getHashVar("name")
    , endyear: getHashVar("endyear")
    , type: getHashVar("type")
    , m: getHashVar("m")
    }
    if (params.fullname || params.endyear || params.type) {
      setSearchResults(params);
    }
  }
  registerOverlays();
}); //DOMContentLoaded


/*
dompack.register("#actionheader .searchform", (node, idx) =>
{                                                                
  node.addEventListener('change', event =>
  {
    let data = {
      fullname: dompack.qS(".searchform #fullname").value
    , schoolclass67year: dompack.qS(".searchform #schoolclass67year").value
    , type: dompack.qS(".searchform #ptype").value
    }
    let addurl ="";
    if (data) {
      addurl = "#/name=" + data.fullname + "/endyear=" + data.schoolclass67year + "/type=" + data.type;
    }
    history.replaceState(data, "Searchresults", addurl);
    let mystate = history.state;
    console.log("form change state:");
    console.log(mystate);
  });
});
*/

/*
dompack.register("#actionheader .searchform", (node, idx) =>
{                                                                
  node.addEventListener('submit', event =>
  {
    alert("form submit");
    event.preventDefault();
    //event.stopPropagation();
  });
});
*/
function onSearchPersonsSuccess(res)
{
  if (res.ok === true) {
    let searchresultsnode = dompack.qS('#searchresults');
    searchresultsnode.innerHTML = "";
    if (res.items.length>0) {
      searchresultsnode.innerHTML = '<p style="margin-left:30px;"><small>L = Leerling, M = Montessori, D = Docent, B = Bestuur, ? = Onbekend, GC= Geen contact, Z = Zoek / geen recente gegevens<br />Rechterbovenhoek: Ja: komt op reünie, Nee: kan niet komen, ?: nog geen keuze</small></p>';
      for (let item of res.items) {
        let nodecontent = '';
        if (item.viewlink.length>0) {
          nodecontent = nodecontent + '<a href="' + item.viewlink + '" title="">';
        }
        nodecontent = nodecontent + '<div class="type">';
        if (item.isstudent) {
          nodecontent = nodecontent + '<span class="student">L</span>';
        }
        if (item.ismontessori) {
          nodecontent = nodecontent + '<span class="montessori">M</span>';
        }
        if (item.isteacher) {
          nodecontent = nodecontent + '<span class="teacher">D</span>';
        }
        if (item.isboard) {
          nodecontent = nodecontent + '<span class="board">B</span>';
        }
        if (!item.isboard && !item.isteacher && !item.isstudent) {
          nodecontent = nodecontent + '<span class="unknown">?</span>';
        }
        if (item.statusz) {
          nodecontent = nodecontent + '<span class="status">' + item.statusz + '</span>';
        }
        if (item.isgc) {
          nodecontent = nodecontent + '<span class="gc">GC</span>';
        }
        nodecontent = nodecontent + '</div>';
        if (!item.fmtdeceased) {
          nodecontent = nodecontent + '<div class="event">';
          if (item.willattendyes) {
            nodecontent = nodecontent + '<span class="attend yes">' + 'Reünie: JA' + '</span>';
          }
          else if (item.willattendno) {
            nodecontent = nodecontent + '<span class="attend no">' + 'Reünie: NEE' + '</span>';
          }
          else if (item.willattendunknown) {
            nodecontent = nodecontent + '<span class="attend unknown">' + 'Reünie: ?' + '</span>';
          }
          nodecontent = nodecontent + '</div>';
        }
        nodecontent = nodecontent + '<div class="img">';
        if (item.wrappedphoto) {
          nodecontent = nodecontent + '<img src="' + item.wrappedphoto.link + '" alt="" /></div><div class="title">';
        }
        nodecontent = nodecontent + item.wrd_fullname;
        if (item.fmtdeceased) {
          nodecontent = nodecontent + '<span class="deceased">(' + item.fmtdeceased + ')</span>';
        }
        nodecontent = nodecontent + '</div>';
        nodecontent = nodecontent + '<div class="dateofbirthyear">Geboren: ' + item.fmtdateofbirthyear + '</div>';
        if (item.isstudent) {
          nodecontent = nodecontent + '<div class="class67year">Eindjaar: ' + ( (item.schoolclass67year>0) ? item.schoolclass67year : '?' ) + '</div>';
        }
        if (item.viewlink.lenght>0) {
          nodecontent = nodecontent + '</a>';
        }
        let addnode = dompack.create("div", { innerHTML: nodecontent });
        addnode.setAttribute("class", "item hspan2");
        dompack.append(searchresultsnode, addnode);
      }
    }
    else {
      //let dialogresult = myDialogFunction();
      //console.log("Dialog results");
      //console.log(dialogresult);
      let addnode = dompack.create("p", { textContent: 'Geen resultaten gevonden voor uw zoekcriteria.' });
      addnode.setAttribute("class", "resultinfo");
      dompack.append(searchresultsnode, addnode);
    }
  }
  else {
    let myerror = "";
    for(let error of res.errors) {
      myerror = myerror + error.text + "\n";
    }
    alert("Melding:\n" + myerror);
  }
  setTimeout(function(){
  dompack.qS("#fullscreenlayer").classList.toggle("hidden", true);
  }, 500);
}

function setSearchResults(_params)
{  
  let param_fullname = _params.fullname || "";
  let param_endyear = _params.endyear || "";
  let param_type = _params.type || "";
  let param_m = _params.m || "";
  dompack.qS(".searchform #fullname").value = param_fullname;
  dompack.qS(".searchform #schoolclass67year").value = param_endyear;
  dompack.qS(".searchform #ptype").value = param_type;
  dompack.qS(".searchform #schoolclassm").value = param_m;
  dompack.qS("#fullscreenlayer").classList.toggle("hidden", false);
  jsonrpc.request( "searchPersons", [ { fullname: param_fullname, schoolclass67year: param_endyear, ptype: param_type, schoolclassm: param_m } ], onSearchPersonsSuccess.bind(this) );
}

dompack.register("#buttonSearchPersons", (node, idx) =>
{                                                                
  node.addEventListener('click', event =>
  {
    event.preventDefault();
    //event.stopPropagation();
    let formdata_fullname = dompack.qS(".searchform #fullname").value;
    let formdata_schoolclass67year = dompack.qS(".searchform #schoolclass67year").value;
    let formdata_ptype = dompack.qS(".searchform #ptype").value;
    let formdata_schoolclassm = dompack.qS(".searchform #schoolclassm").value;
    let params = {
      fullname: formdata_fullname
    , endyear: formdata_schoolclass67year
    , type: formdata_ptype
    , m: formdata_schoolclassm
    };
    let addurl ="";
    if (params) {
      addurl = "#search/name=" + params.fullname + "/endyear=" + params.endyear + "/type=" + params.type + "/m=" + params.m;
    }
    history.pushState(params, "Searchresults", addurl);
    let mystate = history.state;
    setSearchResults(params);
  });
});
/*
place this somewhere in your HTML:

<div class="withoverlays" style="position: relative; width: 500px; height: 500px; background-color: #EFE;">
</div>                                                                                                     
*/                                                                                                         

function onInfoSuccess(res)
{
  //console.log(res);
}
//new JSONRPC({ url: "/wh_services/reunistenvandebsv/rpc/" }).request( "getInfo", [ document.referrer, 440 ], onInfoSuccess.bind(this) );

class OverlayControl
{
  constructor(_overlaymanager, _node)
  {
      dompack.qSA("aside .menu li").forEach(function(n, index) {
        n.remove();
      });
    this.overlaymanager = _overlaymanager;
    this.containernode = _node;
    this.imgnode = dompack.qS(_node, 'img');
    this._setOverlayTranslation();
    this.overlayitems = [];
    this.jsonrpc = new JSONRPC({ url: "/wh_services/reunistenvandebsv/rpc/" });
    this._getRemoteOverlayItems();
    //this._setOverlayTranslation();
    //this.showOverlays();
  }

  refreshData()
  {
    this.hideOverlays();
    this.overlayitems = [];
    this._getRemoteOverlayItems();
  }

  _getRemoteOverlayItems()
  {
    let dataid = this.imgnode.getAttribute("data-id");
    this.jsonrpc.request( "getOverlayItems", [ dataid ], this._onRPCSuccess.bind(this));
  }

  _onRPCSuccess(res)
  {
    if (res.ok) {
      for (let item of res.items) {
        this.overlayitems.push(item);
      }
      this.showOverlays();
      //this.addPersons()
    }
  }

  addPersons()
  {
    let self = this;
    let menu = dompack.qS("aside .menu ul");
    this.overlayitems.forEach(function(e, index){
      let wrd_fullname = (e.wrd_fullname==="") ? "Onbekend" : e.wrd_fullname;
      let addnode = dompack.create("li", { textContent: wrd_fullname });

      addnode.addEventListener('click', event =>
      {                                                                
        self.overlayitems[index].overlay.menuitem = addnode;
        if (e.overlay) {
          self.overlaymanager.setSelection([e.overlay]);
          dompack.qSA("aside .menu li").forEach(function(n, index) {
            dompack.toggleClass(n, "current", false);
          });
          dompack.toggleClass(addnode, "current", true);
        }
      });                                                                                                                          
      dompack.append(menu, addnode);
    });
  }

  getSelectedOverlay()
  {
    let selecteditem = false;
    this.overlayitems.forEach(function(e,index){
      if (e.overlay.selected) {
        selecteditem = e;
      }
    });
    return selecteditem;
  }

  showOverlays()
  {
    let self = this;
    let menu = dompack.qS("aside .menu ul");
    this.overlayitems.forEach(function(e, index){
      let area = { type: 'rectangle', top: e.top, left: e.left, width: e.width, height: e.height };
//console.log(area);
      area = self._translateOverlayArea(area, true);
//console.log(area);
      let newoverlay = self.overlaymanager.addRectangle(area);
//console.log(newoverlay);
      newoverlay.oindex = index;
      dompack.append(newoverlay.getContentNode(), dompack.create("span", { className: "overlaytitle", textContent: e.wrd_fullname }));

      let wrd_fullname = (e.wrd_fullname==="") ? "Onbekend" : e.wrd_fullname;
      let addnode = dompack.create("li", { textContent: wrd_fullname });

      addnode.addEventListener('click', event =>
      {                                                                
        self.overlaymanager.setSelection([newoverlay]);
      });
/*
      dompack.qSA("aside .menu li").forEach(function(n, index) {
        dompack.toggleClass(n, "current", false);
      });
      dompack.toggleClass(addnode, "current", true);
*/
      newoverlay.refmenuitem = addnode;
      dompack.append(menu, addnode);

/*
      newoverlay.nodes.container.addEventListener('click', event =>
      {
        console.log("overlay selection click");
        console.log(self.overlayitems[index]);

      });
*/
      self.overlayitems[index].overlay = newoverlay;
/*
*/
    });
/*
*/
  }

  hideOverlays()
  {
    this.overlayitems.forEach(function(e, index){
      if (e.overlay)
      {
        e.overlay.remove();
      }
    });
  }

  _setOverlayTranslation()
  {
//console.log("imgnode");
//console.log(this.imgnode);
    this.overlaytranslation = { widthratio: this.imgnode.naturalWidth / this.imgnode.offsetWidth
                              , heightratio: this.imgnode.naturalHeight / this.imgnode.offsetHeight
                              };
  }

  refreshOverlayTranslation()
  {
    let self = this;
    this._setOverlayTranslation();
    this.overlayitems.forEach(function(e, index){
      let area = { type: 'rectangle', top: e.top, left: e.left, width: e.width, height: e.height };
      area = self._translateOverlayArea(area, true);
      if (e.overlay)
      {
        e.overlay.update(area);
      }
    });
  }

  _translateOverlayArea(_area, _toimage)
  {
    switch (_area.type)
    {
      case "rectangle":
      {
        _area = { ..._area };
        if (_toimage)
        {
//console.log("heightratio");
//console.log(this.overlaytranslation.heightratio);
//console.log("widthratio");
//console.log(this.overlaytranslation.widthratio);

          _area.top /= this.overlaytranslation.heightratio;
          _area.left /= this.overlaytranslation.widthratio;
          _area.height /= this.overlaytranslation.heightratio;
          _area.width /= this.overlaytranslation.widthratio;
        }
        else
        {
          return (
            { type:   "rectangle"
            , top:    Math.round(_area.top * this.overlaytranslation.heightratio)
            , left:   Math.round(_area.left * this.overlaytranslation.widthratio)
            , height: Math.round(_area.height * this.overlaytranslation.heightratio)
            , width:  Math.round(_area.width * this.overlaytranslation.widthratio)
            });
        }
        return _area;
      }
    }
  }
}

dompack.register("div.formfield.addressjson", (node, idx) =>
{                                               
  let editbutton = dompack.qS(node, "input.button.editaddress");
  if (editbutton) {
    editbutton.addEventListener('click', event =>
    {                                                                
      let dialogresult = editAddressDialog(node);
    });
  }
});

dompack.register("#photoField", (node, idx) =>
{                                               
  node.addEventListener('change', event =>
  {                                                                
    dompack.qS("#fullscreenlayer").classList.toggle("hidden", false);
    var raw = event.target.files[0];
    var reader  = new FileReader();
    if (raw) {
      reader.onloadend = function () {
        jsonrpc.request( "resizePersonalImage", [ this.result ], function(res) {
          dompack.qS("#photopreview img").setAttribute("src", res.srcdata);
          dompack.qS("#fullscreenlayer").classList.toggle("hidden", true);
          dompack.qS("#photo__i_delete").checked = false;
          dompack.qS("#photo__i_delete").parentNode.classList.toggle("nocss", false);
        });
      };
      reader.readAsDataURL(raw);
      //reader.readAsBinaryString(raw);
    }
    else {
      //console.log("is not raw");
    }
    //jsonrpc.request( "resizePersonalImage", [ ], function(res) {
    //,console.log(res);
  });
});

dompack.register("#photo__i_delete", (node, idx) =>
{                                               
  node.addEventListener('change', function(e) {
    if (this.checked) {
      dompack.qS("#photopreview img").setAttribute("src", "");
      this.parentNode.classList.toggle("nocss", true);
    }
  });
});

dompack.register("input[name=willdonateint]", (node, idx) =>
{                                               
  node.addEventListener('change', function(e) {
    if (this.value==2) {
      dompack.qS(".formfield.money").classList.toggle("nocss", true);
    }
    else {
      dompack.qS(".formfield.money").classList.toggle("nocss", false);
    }
  });
});

dompack.register("#accountsettingsform #isdonor", (node, idx) =>
{                                               
  node.addEventListener('click', event =>
  {
    event.preventDefault();
    event.stopPropagation();
  });
});

function registerOverlays() {
dompack.register(".withoverlays", (node, idx) =>
{                                               
  let mybounds = { left: 0, top: 0, right: node.clientWidth-1, bottom: node.clientHeight-1 };
  let overlaymgr = new OverlayManager(node, "myoverlay", { bounds: mybounds, allowcreate: false });

  let overlaycontrol = new OverlayControl(overlaymgr, node);


  dompack.qS(node.parentNode, "#toggleimageoverlays").addEventListener('change', function(e) {
    if (this.checked) {
      //overlaycontrol.showOverlays();
      dompack.qSA(node.parentNode, ".myoverlay").forEach(function(e,index) { dompack.toggleClass(e, "nocss", false);});
    } else {
      //overlaycontrol.hideOverlays();
      dompack.qSA(node.parentNode, ".myoverlay").forEach(function(e,index) { dompack.toggleClass(e, "nocss", true);});
    }
  });

/*
  dompack.qS(node.parentNode, "#provideinfo").addEventListener('click', function(e) {
    let selectedoverlay = overlaycontrol.getSelectedOverlay();
    console.log("selected overlay");
    console.log(selectedoverlay);
    if (selectedoverlay) {
      alert("do dialog");
    }
    else {
      alert("Nog geen persoon geselecteerd.");
    }
  });
*/

  dompack.qS(node.parentNode, "#openperson").addEventListener('click', function(e) {
    let selectedoverlay = overlaycontrol.getSelectedOverlay();
    if (selectedoverlay) {
      if (selectedoverlay.viewlink) {
        window.open(selectedoverlay.viewlink, "_self");
      }
      else {
        alert("Persoon nog onbekend.");
      }
    }
    else {
      alert("Nog geen persoon geselecteerd.");
    }
  });

  window.addEventListener('dompack:movestart', event =>
  {
    //if ( (event.detail.listener.classList.contains("myoverlay")) || (event.detail.listener.classList.contains("withoverlays")) )
    //if ( (event.target.classList.contains("myoverlay")) )
    if ( (event.target.classList.contains("myoverlay")) || (event.target.classList.contains("myoverlay__dragger")) )
    {
      event.stopPropagation();
      event.preventDefault();
    }
  }, true);

  //setTimeout(function(){overlaycontrol.hideOverlays();}, 3000);
  //setTimeout(function(){overlaycontrol.showOverlays();}, 6000);
  //setTimeout(function(){overlaycontrol.overlaymanager.setSelection([]);}, 9000);
  //setTimeout(function(){overlaycontrol.refreshData();}, 15000);

  window.addEventListener('resize', function() {
    overlaycontrol.refreshOverlayTranslation();
  }, true);

  node.addEventListener('dompack:overlay-areachange', event =>
  {
    return;
  });

  node.addEventListener('dompack:overlay-selectionchange', event =>
  {
    //dompack.qS('#selection').textContent = overlaymgr.getSelection().map(node => node.getContentNode().textContent).join(', ');
    let selectedoverlay = overlaycontrol.getSelectedOverlay();
    if (selectedoverlay) {
      dompack.qSA("aside .menu li").forEach(function(n, index) {
        dompack.toggleClass(n, "current", false);
      });
      dompack.toggleClass(selectedoverlay.overlay.refmenuitem, "current", true);
    }
/*
    overlaycontrol.overlayitems.forEach(function(e,index){
      if (e.overlay.selected) {
        console.log("selected overlayitem");
        console.log(e);
      dompack.qSA("aside .menu li").forEach(function(n, index) {
        dompack.toggleClass(n, "current", false);
      });
      dompack.toggleClass(e.overlay.refmenuitem, "current", true);
      }
    });
*/
/*
    console.log("selected overlay oindex: " + selectedoverlay.oindex);
    console.log(selectedoverlay.oindex);
    console.log("selected overlay menuitem");
    console.log(selectedoverlay.refmenuitem);/
/*
      dompack.qSA("aside .menu li").forEach(function(n, index) {
        dompack.toggleClass(n, "current", false);
      });
      dompack.toggleClass(selectedoverlay.refmenuitem, "current", true);
*/
/*
    if (selectedoverlay) {
      dompack.qSA("aside .menu li").forEach(function(n, index) {
        dompack.toggleClass(n, "current", false);
      });
      selectedoverlay.menuitem.toggleClass(addnode, "current", true);
    }
*/
  });                                                                                                                          

  node.addEventListener('click', event =>
  {                                                                
    //dompack.qS('#selection').textContent = overlaymgr.getSelection().map(node => node.getContentNode().textContent).join(', ');
  });                                                                                                                          

  window.addEventListener('blur', event =>
  {                                                                
    //dompack.qS('#selection').textContent = overlaymgr.getSelection().map(node => node.getContentNode().textContent).join(', ');
    overlaycontrol.overlaymanager.setSelection();
  });                                                                                                                          

/*
  let imgnode = dompack.qS(node, 'img');
  let overlaytranslation = { widthratio: imgnode.naturalWidth / imgnode.offsetWidth
                           , heightratio: imgnode.naturalHeight / imgnode.offsetHeight
                           , imgnaturalwidth: imgnode.naturalWidth
                           , imgnaturalheight: imgnode.naturalHeight
                           , imgoffsetwidth: imgnode.offsetWidth
                           , imgoffsetheight: imgnode.offsetHeight
                           };
  console.log(overlaytranslation);
  node.addEventListener('dompack:overlay-selectionchange', event =>
  {                                                                
    dompack.qS('#selection').textContent = overlaymgr.getSelection().map(node => node.getContentNode().textContent).join(', ');
  });                                                                                                                          

  node.addEventListener('dompack:overlay-selectionchange', event =>
  {                                                                
    let node = dompack.qS('#selectionchanges' + (event.detail.useraction ? 'user' : ''));
    node.textContent = (parseInt(node.textContent) || 0) + 1;                            
  });                                                                                    
  node.addEventListener('dompack:overlay-areachange', event =>                           
  {                                                                                      
    let node = dompack.qS('#areachanges' + (event.detail.useraction ? 'user' : ''));     
    node.textContent = (parseInt(node.textContent) || 0) + 1;                            
  });                                                                                    
  node.addEventListener('dompack:overlay-created', event =>                              
  {                                                                                      
    let node = dompack.qS('#created');                                                   
    node.textContent = (parseInt(node.textContent) || 0) + 1;                            
  });                                                                                    
*/

/*
  let amiga = overlaymgr.addRectangle({ left: 5, top: 50, width: 50, height: 150 });
  dompack.append(amiga.getContentNode(), dompack.create("span", { className: "overlaytitle", textContent: "Amiga" }));

  let apple = overlaymgr.addRectangle({ left: 45, top: 250, width: 400, height: 50 });
  dompack.append(apple.getContentNode(), dompack.create("span", { className: "overlaytitle", textContent: "Apple" }));

  let atari = overlaymgr.addRectangle({ left: 380, top: 30, width: 80, height: 250 });
  dompack.append(atari.getContentNode(), dompack.create("span", { className: "overlaytitle", textContent: "Atari" }));

  overlaymgr.setSelection([amiga]);
*/
/*
  let overlayitems = [];
  new JSONRPC({ url: "/wh_services/reunistenvandebsv/rpc/" }).request( "getInfo", [ document.referrer, 440 ], function(res){
    if (res.ok) {
      for (let item of res.items) {
        let newoverlay = overlaymgr.addRectangle({ left: (1/overlaytranslation.widthratio) * item.left, top: (1/overlaytranslation.heightratio) * item.top, width: (1/overlaytranslation.widthratio) * item.width, height: (1/overlaytranslation.heightratio) * item.height });
        dompack.append(newoverlay.getContentNode(), dompack.create("span", { className: "overlaytitle", textContent: item.wrd_fullname }));
        overlayitems.push(item);
      }
      console.log(overlayitems);
    }
  });
*/
/*
  window.overlaytests = { overlaymgr, amiga, apple, atari }; //allow tests to access us


  let myoverlay1 = new ResizeableOverlay(node, "myoverlays",
        { enabled: true
        , left: 5, top: 50, width: 50, height: 150, selected: true, title: "Amiga", bounds: mybounds
        });

  let myoverlay2 = new ResizeableOverlay(node, "myoverlays",
        { enabled: true
        , left: 45, top: 250, width: 400, height: 50, title: "Apple", bounds: mybounds
        });

  let myoverlay3 = new ResizeableOverlay(node, "myoverlays",
        { enabled: true
        , left: 380, top: 30, width: 80, height: 250, title: "Atari", bounds: mybounds, enabled: false
        });*/
});
}

async function myDialogFunction()
{
  let dialog = dialogapi.createDialog({allowcancel:false});
  let button = dompack.create('button', { className: 'mybutton'
                                        , textContent: 'Ok'
                                        , type: 'button'
                                        , on: { click: event => dialog.resolve( { boom: true } )
                                              }
                                        });
  dialog.contentnode.appendChild(button);

  let result = await dialog.runModal();
}

function refreshAddressDialog(_dialog, _fieldnode, _formdata, _init)
{
  dompack.qS("#fullscreenlayer").classList.toggle("hidden", false);
  jsonrpc.request( "getAddressFormData", [ _formdata ], function(res) {
    dompack.qS("select.country").innerHTML = res.countrylistoptionshtml;
    if (_init) {
      //dompack.qS("select.country").value = "NL";
    }
    
    if (res.formdata && res.formdata.formdata) {
      dompack.qS("select.country").value = res.formdata.formdata.country;
    }
    //dialog.contentnode.insertAdjacentHTML('beforeend', res.html);
    dompack.qS(_dialog.contentnode, "div.varfields").innerHTML = res.html;
  setTimeout(function(){
  dompack.qS("#fullscreenlayer").classList.toggle("hidden", true);
  }, 500);
    if (!_init && res.ok) {
      dompack.qS(_fieldnode, "input.address").value = JSON.stringify(getDialogCurrentAddressValue(_dialog));
      let myparent = _fieldnode.parentNode;
      dompack.qS(_fieldnode.parentNode, "textarea").value = res.fmtaddress;
      _dialog.resolve( { ok: true } );
    }
  } );
}

function getDialogCurrentAddressValue(_dialog, _fieldnode)
{
  let countrycode = dompack.qS("select.country").value || "NL";
  let result = { "country": countrycode };
  let fieldinputs = dompack.qSA(_dialog.contentnode, "div.varfields .formfield input");
  fieldinputs.forEach(function(e, index) {
    result[e.name] = e.value;
  });
  return result;
}

async function editAddressDialog(_fieldnode)
{
  let dialog = dialogapi.createDialog({allowcancel:false});
  let countryselect = dompack.create('select', { className: 'country'
                                               , name: 'selectCountry'
                                               , on: { change: function(e) { let result = refreshAddressDialog(dialog, _fieldnode, JSON.stringify(getDialogCurrentAddressValue(dialog, _fieldnode)), true ); }
                                                     }
                                               });
  let okbutton = dompack.create('button', { className: 'button'
                                          , textContent: 'Ok'
                                          , type: 'button'
                                          //, on: { click: event => dialog.resolve( { ok: true } )
                                          , on: { click: function(e) { let result = refreshAddressDialog(dialog, _fieldnode, JSON.stringify(getDialogCurrentAddressValue(dialog, _fieldnode)) ); }
                                                }
                                          });
  let cancelbutton = dompack.create('button', { className: 'button'
                                              , textContent: 'Annuleren'
                                              , type: 'button'
                                              , on: { click: event => dialog.resolve( { ok: false } )
                                                    }
                                              });
  let formnode = dialog.contentnode.appendChild(dompack.create('form', { className: 'standardform labelsabove', textContent: '' }));
  //dialog.contentnode.appendChild(dompack.create('h2', { textContent: 'Adres bewerken'}));
  formnode.appendChild(dompack.create('h2', { textContent: 'Adres bewerken'}));
  let formfooter = dompack.create('div', { className: 'formfooter', textContent: '' });
  let formfield = dompack.create('div', { className: 'formfield', textContent: '' });
  let formfieldlabel = dompack.create('label', { 'for': 'selectCountry', textContent: 'Land' });
  let formfieldpart1 = dompack.create('div', { className: 'part1', textContent: '' });
  let formfieldpart2 = dompack.create('div', { className: 'part2', textContent: '' });
  formfieldpart1.appendChild(formfieldlabel);
  formfieldpart2.appendChild(countryselect);
  formfield.appendChild(formfieldpart1);
  formfield.appendChild(formfieldpart2);
  formnode.appendChild(formfield);
  formnode.appendChild(dompack.create('div', { className: 'varfields', textContent: '' }));
  formfooter.appendChild(okbutton);
  formfooter.appendChild(cancelbutton);
  formnode.appendChild(formfooter);

  let fieldinput = dompack.qS(_fieldnode, "input.address");
  if (fieldinput) {
    refreshAddressDialog(dialog, _fieldnode, fieldinput.value, true);
  }


  let result = await dialog.runModal();
}
